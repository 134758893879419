import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import { useDeepDivePanelStore } from "@hooks";
import Typography from "@ui-kit/Typography";
import useStyles from "./styles.jss";

const BulletinBoardView: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [{ facility }] = useDeepDivePanelStore();
  const classes = useStyles();

  return (
    <Dialog
      open
      onClose={() => {
        history.replace({
          pathname: `/map/${facility.id}`,
          search,
        });
      }}
      title="Bulletin board"
      data-test="bulletin-board-dialog"
      tier={facility.subscriptionTier}
    >
      <div className={classes.root}>
        {facility.bulletinBoardHeading !== null && (
          <Typography bolded paragraph data-test="bulletinBoardHeading-display">
            {facility.bulletinBoardHeading}
          </Typography>
        )}

        <Typography paragraph data-test="bulletinBoardOverview-display">
          {facility.bulletinBoardOverview}
        </Typography>
      </div>
    </Dialog>
  );
};

export default BulletinBoardView;
